import '../styles/components/tag.scss'

import React from 'react'

import { classNameMap } from '../utils/tsx'

export interface TagProps {
  selected?: boolean
  onClick?: () => void
  eventsDisabled?: boolean
  disabledCursor?: boolean
}

export const Tag: React.FC<TagProps> = ({
  children,
  onClick,
  selected,
  eventsDisabled = false,
  disabledCursor = false,
}) => {
  return (
    <span
      className={classNameMap({
        tag: true,
        selected,
        eventsDisabled,
        disabledCursor,
      })}
      onClick={onClick}
    >
      {children}
    </span>
  )
}
